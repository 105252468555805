<template>
  <v-row class="pa-4">
    <v-col cols="12" class="d-flex justify-space-between">
      <div>
        <v-row class="align-center mt-4">
          <v-icon large class="mr-2 mb-1">mdi-format-font</v-icon>
          <h1 style="font-weight: 400;">{{ (campaignAssets.length > 0) ? 'Assets Added' : 'Add Assets' }}</h1>
        </v-row>
        <!-- Woff Fonts -->
        <v-row
          v-if="$store.state.Permissions.campaignEdit && campaignAssets.filter((x)=>x.media.originalFileName.split('.').pop() == 'woff').length > 0"
        >
          <h2 class="text-h2 text--primary"><br>Fonts</h2>
        </v-row>
        <v-row 
          v-if="$store.state.Permissions.campaignEdit"
          class="d-flex justify-start flex-start align-start"
          style="column-gap: 1.5em;"
        >
          <v-card
            max-width="344"
            min-width="344"
            min-height="270px"
            height="auto"
            v-for="(asset, index) in campaignAssets.filter((x)=>x.media.originalFileName.split('.').pop() == 'woff')" :key="index"
            outlined
          >
            <v-card-text
            >
              <div class="text--primary">{{ asset.media.originalFileName }}</div>
              <p class="text-h4">
                Used in {{ asset.usageCount }} assets.<br/>
                Embeddable: {{ asset.embeddable }}<br/>
                Created on: {{ asset.dateCreated }}<br/>
                <span v-if="asset.uploadedBy">Created by: {{ asset.uploadedBy }}</span><br/>
              </p>
              <div 
                class="text--primary" 
                :style="{ 
                  fontFamily: `${asset.media.originalFileName.split('.').slice(0, -1).join('.')}WOFF`,
                  fontSize: '20px',
                  lineHeight: '1.2em' 
                  }"
              >
                <p>{{ fontText[asset.index] }}</p>
              </div>
            </v-card-text>
          </v-card>
        </v-row>
        <!-- TTF Fonts -->
        <v-row
          v-if="$store.state.Permissions.campaignEdit && campaignAssets.filter((x)=>x.media.originalFileName.split('.').pop() == 'ttf').length > 0"
        >
          <h2 style="font-weight: 400;"><br>TTF Fonts</h2>
        </v-row>
        <v-row
          v-if="$store.state.Permissions.campaignEdit"
          class="d-flex justify-start flex-start align-start"
          style="column-gap: 1.5em;"
        >
          <v-card
            max-width="344"
            min-width="344"
            min-height="270px"
            height="auto"
            v-for="(asset, index) in campaignAssets.filter((x)=>x.media.originalFileName.split('.').pop() == 'ttf')" :key="index"
            outlined
          >
            <v-card-text
            >
              <div>{{ asset.media.originalFileName }}</div>
              <p>
                Created on: {{ asset.dateCreated }}<br/>
                <span v-if="asset.uploadedBy">Created by: {{ asset.uploadedBy }}</span><br/>
              </p>
              <div
                class="text--primary mb-4" 
                :style="{ 
                  fontFamily: `${asset.media.originalFileName.split('.').slice(0, -1).join('.')}TTF`,
                  fontSize: '20px',
                  lineHeight: '1em',
                  minHeight: '80px'
                  }"
              >
                <p
                >{{ fontText[asset.index] }}</p>
              </div>
            </v-card-text>
            <v-card-actions
              class="pt-0"
              style="justify-content: center"
            >
              <v-btn
                width="80%"
                text
                color="primary"
                @click="getWoffFromGenerator(asset)"
              >
                Convert to Woff
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-row>
        <!-- DOCUMENTS: xlsx, ppt, word, pdf, txt-->
        <v-row
          v-if="campaignAssets.filter((x)=>isDocument(x.media.originalFileName)).length > 0"
        >
          <h2 style="font-weight: 400;"><br>Documents</h2>
        </v-row>
        <v-row 
          class="d-flex justify-start flex-start align-start" 
          style="column-gap: 1.5em;">
          <v-card
            max-width="344"
            min-width="344"
            height="auto"
            style="text-align: center;"
            class="pa-2"
            v-for="(asset, index) in campaignAssets.filter((x)=>isDocument(x.media.originalFileName))" :key="index"
            outlined
          >
          <v-card-text 
              style="width: 100%;min-height:140px;"
              class="ma-1"
            >
              <div 
                style="min-height:50px;"
              >
                <span
                  style=""
                >
                  <i 
                    v-if="asset.media.originalFileName.split('.').pop() == 'xls' || asset.media.originalFileName.split('.').pop() == 'xlsx'"
                    data-v-483f777a="" aria-hidden="true" class="v-icon notranslate mr-1 mdi mdi-microsoft-excel theme--dark"
                  ></i>
                  <i 
                    v-if="asset.media.originalFileName.split('.').pop() == 'doc' || asset.media.originalFileName.split('.').pop() == 'docx'"
                    data-v-483f777a="" aria-hidden="true" class="v-icon notranslate mr-1 mdi mdi-microsoft-word theme--dark"
                  ></i>
                  <i 
                    v-if="asset.media.originalFileName.split('.').pop() == 'pptx'"
                    data-v-483f777a="" aria-hidden="true" class="v-icon notranslate mr-1 mdi mdi-microsoft-powerpoint theme--dark"
                  ></i>
                  <i 
                    v-if="asset.media.originalFileName.split('.').pop() == 'pdf'"
                    data-v-483f777a="" aria-hidden="true" class="v-icon notranslate mr-1 mdi mdi-file-pdf-box theme--dark"
                  ></i>
                  <i 
                    v-if="asset.media.originalFileName.split('.').pop() == 'csv'"
                    data-v-483f777a="" aria-hidden="true" class="v-icon notranslate mr-1 mdi mdi-file-delimited theme--dark"
                  ></i>
                  <i 
                    v-if="asset.media.originalFileName.split('.').pop() == 'txt'"
                    data-v-483f777a="" aria-hidden="true" class="v-icon notranslate mr-1 mdi mdi-text-box theme--dark"
                  ></i>
                </span>
                <a :href="asset.media.url" target="_blank">{{ asset.media.originalFileName }}</a>
              </div>
              <p class="pa-0 ma-0">
                Created on: {{ asset.dateCreated }}<br/>
                <span v-if="asset.uploadedBy">Created by: {{ asset.uploadedBy }}<br/></span>
                File size: {{ formatFileSize(asset.media.fileSize) }}
              </p>
          </v-card-text>
          </v-card>
        </v-row>
      </div>
       <!-- drag & drop upload -->
       <div
          v-if="$store.state.Permissions.proofOfPlay"
          @click="$refs.fontsUpload.click()"
          @dragover.prevent
          @drop.prevent
          style="width: 150px;"
        >
          <input
            @click="$refs.fontsUpload.value = ''"
            ref="fontsUpload"
            type="file"
            multiple
            hidden
            @change="uploadFont"
          />
            <v-tooltip top>
              <template v-slot:activator="{ on, attrs }">
                <v-hover v-slot="{ hover }">
                  <div
                    ref="dragDiv"
                    v-bind="attrs"
                    v-on="on"
                    @drop="dragFile"
                    @dragleave="$refs.dragDiv.style.backgroundColor = 'transparent'"
                    @dragover="$refs.dragDiv.style.backgroundColor = 'grey'"
                    style="
                      background-color: transparent;
                      padding: 0.66em 0em;
                      transition: 0.1s;
                    "
                    :style="hover ? {border: 'solid 1px rgba(255,255,255, .8)'} : {border: 'solid 1px rgba(255,255,255, .3)'}"
                    class="rounded"
                  >
                    <div style="width:100%; text-align:center;">
                      <v-icon large>
                        mdi-cloud-upload
                      </v-icon>
                    </div>
                  </div>
                </v-hover>
              </template>
                <span>Upload Files</span>
            </v-tooltip>
          </div>
      </v-col>

    <v-dialog
      v-model="showFileUploadModal"
      @click:outside="filesForUpload = null; showFileUploadModal = false;"
      width="500"
    >
      <v-card>
        <v-card-title class="pa-4 white--text primary">
          Upload Assets
        </v-card-title>

        <div class="pa-2">
          <ul>
            <li v-for="(file, index) in filesForUpload" :key="index"><span class="text-h5">{{ file.name }}</span></li>
          </ul>

          <div class="d-flex justify-end mt-4">
            <v-btn 
              @click="uploadAssets()"
              color="primary"
              class="mr-2"
            >Upload</v-btn>
            <v-btn 
              @click="filesForUpload = null; showFileUploadModal = false;"
              color="red" 
            >Cancel</v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
    <!-- Shows warning message when the files aren't allowed format -->
    <v-dialog
      v-model="showWarningMessage"
      @click:outside="showWarningMessage = false;"
      width="500"
    >
      <v-card>
        <v-card-title class="pa-4 white--text primary">
          Invalid File Formats
        </v-card-title>

        <div class="pa-2">
          <ul>
            <p style="text-align: center;">
              Warning some files have unsupported formats!
            </p>
            <li v-for="(file, index) in filesForUpload" :key="index"
              class="ml-4"
            >
              <span 
                class="text-h5"
                :style="!isValidFileType(file.name) ? { backgroundColor: 'red', color: 'white' } : {}"
              >{{ file.name }}</span></li>
          </ul>

          <div class="d-flex justify-end mt-4">
            <v-btn 
              @click="showWarningMessage = false;"
              color="red" 
            >Cancel</v-btn>
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-row>
</template>
  
<script>
  // Services
  import CampaignController from '@/services/controllers/Campaign'
  import axios from 'axios'

  export default {
    props: {
      campaign: {
        type: Object,
        required: true,
      },
    },

    data () {
      return {
        localCampaign: null,
        campaignAssets: [],
        filesForUpload: null,
        showFileUploadModal: false,
        showWarningMessage: false,
        showFontConvertionModal: false,
        revealedCards: [],
        fontText: [],
      }
    },
    
    created () {
      // Make local copy
      this.localCampaign = JSON.parse(JSON.stringify(this.campaign))

      this.getAssets()
    },

    methods: {
      // Cards reveal aniamtion
      revealCard(index) {
        // Set the 'reveal' status for the card at the given index to true.
        this.revealedCards.push({ index, reveal: true });
      },
      hideCard(index) {
        // Find the index of the card with the given index in the 'revealedCards' array and set its 'reveal' status to false.
        const cardIndex = this.revealedCards.findIndex((card) => card.index === index);
        if (cardIndex !== -1) {
          this.revealedCards.splice(cardIndex, 1);
        }
      },
      isCardRevealed(index) {
        // Check if the card at the given index is revealed based on the 'revealedCards' array.
        return this.revealedCards.some((card) => card.index === index && card.reveal);
      },
      // end card reveal animation
      async getAssets() {
        const { data = null } = await CampaignController.getAssets(this.campaign.id)
        .catch(error => { this.$root.$emit("snackbarError", error.response.data.message) })

        if(data == null) return this.campaignAssets = [];

        // Load the fonts to see them
        this.fontText = new Array(data.length).fill("Loading...");
        for(let i = 0; i < data.length;i++) {
          // Get around the filtering and have tha asset know it's own index for the fontText to work
          data[i].index = i;
          var fontFamily = data[i].media.originalFileName.split('.').slice(0, -1).join('.');
          var fontExtention = data[i].media.originalFileName.split('.').pop().toUpperCase();
          if(data[i]?.media?.mimeType == "application/font-woff" || data[i]?.media?.mimeType == "application/x-font-ttf") {
            fontFamily += fontExtention;
//            console.log("fontFamily",fontFamily);
            document.fonts.add(new FontFace(fontFamily,`url('${data[i].media.url}')`));
            (new FontFace(fontFamily,`url('${data[i].media.url}')`)).load().then(()=>{
                //document.fonts.add(new FontFace(fontFamily,`url('${data[i].media.url}')`));
                  this.$set(this.fontText, i, 
"The quick brown fox jumps over the lazy dog. The five boxing wizards jump quickly. Glib jocks quiz nymph to vex dwarf."
                  );
            }).catch((err)=>{
              console.log("ERROR: ",err); 
              this.$set(this.fontText, i, "Error the font failed to load.");
            });
          } else {
            data[i].embeddable = false;
          }
        }
        this.campaignAssets = [...data];
      },

      async uploadAssets() {
        for (let index = 0; index < this.filesForUpload.length; index++) {
          const file = this.filesForUpload[index];
          
          const formData = new FormData()
          formData.append('file', file)
          formData.append('campaignId', this.campaign.id)

          const mediaRes = await CampaignController.uploadMedia(formData).catch(error => { 
            this.$root.$emit("snackbarError", error.response.data.message) 
          })

          await CampaignController.addAsset({ campaignId: this.campaign.id, mediaId: mediaRes.data.id })
          .catch(error => { this.$root.$emit("snackbarError", error.response.data.message) })
        }

        this.filesForUpload = null
        this.showFileUploadModal = false

        this.getAssets()
      },
      async getWoffFromGenerator(asset) {
        console.log("getWoffFromGenerator ",asset);
        axios.get(process.env.VUE_APP_GENERATOR_URL + 
          'generateWoff?originalFileName=' + asset.media.originalFileName +
          '&url='+asset.media.url
          , {
            responseType: 'arraybuffer',
            headers: { 'Content-Type': 'multipart/form-data' }
          }).then(async (res) => {
            console.log("response from generator");
            let fileName = asset.media.originalFileName.replace(/\.ttf$/, '.woff');
            const file = new File([res.data], fileName, { type: 'application/font-woff' })
            
            try {
              const formData = new FormData()
              formData.append('file', file)
              formData.append('campaignId', this.campaign.id)

              const mediaRes = await CampaignController.uploadMedia(formData).catch(error => { 
                this.$root.$emit("snackbarError", error.response.data.message) 
              })

              console.log("mediaRes",mediaRes);
              console.log("this.campaign.id",this.campaign.id);

              var res = await CampaignController.addAsset({ campaignId: this.campaign.id, mediaId: mediaRes.data.id })
              .catch(error => { this.$root.$emit("snackbarError", error.response.data.message) })
              this.getAssets();
            } catch(err) {
              console.log(err);
            }

            return file;
        })
        .catch((err) => {
          console.log("Error: ",err);
          this.$root.$emit('snackbarError', 'Failed connecting to Generate woff from ttf');
        })
        return null;
      },

      // Purpose: Execute after files are selected from the explorer
      uploadFont(e) {
        const files = Object.values(e.target.files)
        this.filesForUpload = files;
        if(files.filter((x)=>!this.isValidFileType(x.name)).length > 0) {
          console.log("Not allowed file format");
          this.showWarningMessage = true;
          return;
        }
        this.showFileUploadModal = true
      },

      // Purpose: Execute after files are dragged & dropped from the explorer
      dragFile (e) {
        const files = Object.values(e.dataTransfer.files)

        if(files.filter((x)=>!this.isValidFileType(x.name)).length > 0) {
          console.log("Not allowed file format");
          this.filesForUpload = files;
          this.showWarningMessage = true;
          return;
        }

        this.filesForUpload = files
        this.showFileUploadModal = true
      },
      isValidFileType(filename) {
        return this.isDocument(filename) || (filename.split(".").pop() == "woff") || (filename.split(".").pop() == "ttf");
      },
      isDocument(filename) {
        if(typeof filename != 'string' ) return false;
        return ['pdf', 'doc', 'docx', 'txt', 'xlsx', 'pptx','csv','xls'].includes(filename.split(".").pop());
      },
      formatFileSize(bytes) {
        return bytes < 500 
          ? bytes + ' bytes' 
          : bytes < 1024 * 500 
          ? (bytes / 1024).toFixed(2) + ' KB' 
          : bytes < 1024 * 1024 * 500 
          ? (bytes / (1024 * 1024)).toFixed(2) + ' MB' 
          : (bytes / (1024 * 1024 * 1024)).toFixed(2) + ' GB';
      }
    },
  }
</script>

<style scoped>
.v-card--reveal {
  bottom: 0;
  opacity: 1 !important;
  position: absolute;
  width: 100%;
}
</style>